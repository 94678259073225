export const makeTable = {
  '0': 'Toyota',
  '1': 'Honda',
  '2': 'Ford',
  '3': 'Nissan',
  '4': 'Chevrolet',
  '5': 'BMW',
  '6': 'Mercedes Benz',
  '7': 'Volkswagen',
  '8': 'Hyundai',
  '9': 'Dodge',
  '10': 'Lexus',
  '11': 'Kia',
  '12': 'Jeep',
  '13': 'Audi',
  '14': 'Mazda',
  '15': 'Other',
  '16': 'GMC',
  '17': 'Infinity',
  '18': 'Chrysler',
  '19': 'Subaru',
  '20': 'Acura',
  '21': 'Volvo',
  '22': 'Land Rover',
  '23': 'Mitsubishi',
  '24': 'Cadillac',
  '25': 'Mini',
  '26': 'Porsche',
  '27': 'Unknown',
  '28': 'Buick',
  '29': 'Freightliner',
  '30': 'Tesla',
  '31': 'Lincoln',
  '32': 'Saturn',
  '33': 'Pontiac',
  '34': 'Grumman',
  '35': 'Fiat',
  '36': 'Jaguar',
  '37': 'Mercury',
  '38': 'Isuzu',
  '39': 'International',
  '40': 'Suzuki',
  '41': 'Saab',
  '42': 'Oldsmobile',
  '43': 'Maserati',
  '44': 'Peterbuilt',
  '45': 'Kenworth',
  '46': 'Smart',
  '47': 'Plymouth',
  '48': 'Hino',
  '49': 'Harley-Davidson',
  '50': 'Alfa Romero',
  '51': 'Hummer',
  '52': 'Bentley',
  '53': 'Yamaha',
  '54': 'Kawasaki',
  '55': 'Geo Metro',
  '56': 'Winnebago',
  '57': 'Rolls-Royce',
  '58': 'Scion',
  '59': 'Triumph',
  '60': 'Checker',
  '61': 'Datsun',
  '62': 'Ferrari',
  '63': 'Sterling',
  '64': 'Lamborghini',
  '65': 'Aston Martin',
  '66': 'Daewoo',
  '67': 'Merkur',
  '68': 'Mack',
  '69': 'CIMC',
  '70': 'MISC.'
};

export const typeTable = {
  'PA': 'Passenger Car',
  'PU': 'Pickup Truck',
  'VN': 'Van',
  'VA': 'Van',
  'TK': 'Truck',
  'TC': 'Truck',
  'TT': 'Truck',
  'CM': 'Commercial Vehicle',
  'CO': 'Commercial Vehicle',
  'SU': 'Sport Utility Vehicle',
  '2D': 'Sedan Two Door',
  '4D': 'Sedan Four Door',
  'SV': 'Sports Van',
  'MC': 'Motorcycle',
  'MO': 'Motorcycle',
  'MS': 'Scooter',
  'SC': 'Scooter',
  'TR': 'Trailer',
  'MH': 'Motorized Home',
  'VH': 'Vehicle Home',
  'RV': 'Recreational Vehicle',
  'CA': 'Camper',
  'CC': 'Camper',
  'HS': 'House Trailer',
  'BU': 'Bus',
  'UT': 'Utility Vehicle',
  'TL': 'Tilt Tandem',
  'LM': 'Limousine',
  'LI': 'Limousine',
  'SW': 'Station Wagon',
  'JE': 'Jeep',
  'TX': 'Taxi',
  'CV': 'Convertible',
};

export const colorTable = {
  'BK': 'Black',
  'WT': 'White',
  'WH': 'White',
  'GY': 'Gray',
  'GR': 'Gray',
  'SL': 'Silver',
  'SI': 'Silver',
  'BL': 'Blue',
  'RD': 'Red',
  'RE': 'Red',
  'GN': 'Green',
  'BN': 'Brown',
  'BR': 'Brown',
  'GO': 'Gold',
  'MR': 'Maroon',
  'MA': 'Maroon',
  'TN': 'Tan',
  'TA': 'Tan',
  'BG': 'Beige',
  'BE': 'Beige',
  'YE': 'Yellow',
  'OR': 'Orange',
  'PR': 'Purple',
  'PU': 'Purple',
  'PK': 'Pink',
  'CO': 'Copper',
  'TU': 'Turquoise',
  'BU': 'Burgandy',
};